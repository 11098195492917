<template>
  <div class="section spacer--padding--b--xl">
    <Grid :col="{ default: 12, s: options.is_two_cols ? 6 : 12 }">
      <div class="column">
        <div
          class="title"
          v-html="data.column_1.title"
        />
        <div
          class="content spacer--padding--t--s"
          v-html="data.column_1.content"
        />
      </div>
      <div
        v-if="options.is_two_cols"
        class="column spacer--padding--t--xl spacer--padding--t-s--0"
      >
        <div
          class="title"
          v-html="data.column_2.title"
        />
        <div
          class="content spacer--padding--t--s"
          v-html="data.column_2.content"
        />
      </div>
    </Grid>
  </div>
</template>

<script>
export default {
  name: 'Section',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    options() {
      return this.data.options;
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  .column {
    .title {
      @extend %typo--text-small;
      border-bottom: 1px solid var(--black);
      padding-bottom: 1px;
      @include mq(s) {
        padding-bottom: var(--spacer-s);
        border-width: 2px;
      }
    }
    .content {
      @extend %typo--text-big;
      line-height: 1.2;
    }
  }
}
</style>
