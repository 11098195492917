<template>
  <div class="archive spacer--padding--b--xl">
    <Heading
      v-if="data.value.title"
      :value="data.value.title"
    />
    <div
      v-if="$mq.breakpoint !== '0'"
      class="filters flex"
    >
      <div
        :class="['btn', { 'btn-active': filter === null }, 'all']"
        @click="setFilter(null)"
      >
        All
      </div>
      <div
        v-for="tag in activeTags"
        :key="tag.id"
        :class="[
          'btn',
          {
            'btn-active': filter === tag.id,
            featured: tag.slug === 'featured',
          },
        ]"
        @click="setFilter(tag.id)"
        v-html="tag.name"
      />
    </div>
    <AccordionWrapper v-else>
      <Accordion>
        <template #accordion-trigger>
          Show Filters
        </template>
        <template #accordion-trigger-active>
          Close Filters
        </template>
        <template #accordion-content>
          <div class="filters grid grid-12">
            <div
              :class="[
                'btn',
                { 'btn-active': filter === null },
                'all',
              ]"
              @click="setFilter(null)"
            >
              All
            </div>
            <div
              v-for="tag in activeTags"
              :key="tag.id"
              :class="[
                'btn',
                {
                  'btn-active': filter === tag.id,
                  featured: tag.slug === 'featured',
                },
              ]"
              @click="setFilter(tag.id)"
              v-html="tag.name"
            />
          </div>
        </template>
      </Accordion>
    </AccordionWrapper>
    <Grid
      v-if="items.length > 0"
      :col="{ default: 12, s: 6 }"
    >
      <div
        v-for="item in items"
        :key="item.id"
        class="item"
      >
        <component
          :is="thumbs.get('default')"
          :data="item"
        />
      </div>
    </Grid>
    <div
      v-else
      style="height: 100vh"
    />

    <div
      v-if="theresMore"
      class="show-more"
      @click="requestOffset"
      v-html="'Show More'"
    />

    <Link
      v-if="data.value.link"
      :data="data.value.link"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Thumb from '@/components/thumb';
import Heading from '@/components/typo/heading';
import Link from '@/components/typo/link';
import AccordionWrapper from '@/components/ui/accordion-wrapper';
import Accordion from '@/components/ui/accordion-item';

const thumbs = new Map();
thumbs.set('default', Thumb);

export default {
  name: 'Archive',
  components: {
    Heading,
    Link,
    AccordionWrapper,
    Accordion,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      thumbs,
      request: null,
      pendingRequest: false,
      items: [],
      theresMore: true,
      offset: 0,
      placeholder: 'Loading',
      filter: null,
      total: null,
    };
  },
  computed: {
    ...mapGetters({
      postType: 'postType',
      tags: 'tags',
      requestFromGetters: 'request',
    }),
    activeTags() {
      return this.tags().filter((tag) => tag.count > 0);
    },
    postsPerPage() {
      let ppp;
      if (this.$mq.breakpoint === '0') {
        ppp = parseInt(this.data.settings.posts_per_page_mobile, 10);
        ppp = typeof ppp === 'number' && ppp > 0 ? ppp : 4;
      } else {
        ppp = parseInt(this.data.settings.posts_per_page, 10);
        ppp = ppp % 2 === 0 ? ppp : ppp + 1;
        ppp = typeof ppp === 'number' && ppp > 1 ? ppp : 8;
      }
      return ppp;
    },
  },
  mounted() {
    this.setRequest();

    this.loadItems().then(() => {
      this.$root.$children[0].lazyObserve(this.$el);

      if (this.items.length === this.total) {
        this.theresMore = false;
      }
    });
  },
  methods: {
    async loadItems() {
      if (!this.pendingRequest) {
        this.pendingRequest = true;

        const items = await this.$store.dispatch(
          'getItems',
          this.request,
        );
        if (!this.total) {
          this.total = this.requestFromGetters(this.request, true);
        }

        if (items) {
          this.items = [...this.items, ...items];
        }

        this.pendingRequest = false;

        return items;
      }
      return [];
    },
    setRequest() {
      const type = this.postType(this.data.value.post_type).rest_base;
      const per_page = this.postsPerPage;
      const order = this.data.settings.order ? 'desc' : 'asc';
      const orderby = this.data.settings.order_by;
      const { offset } = this;

      const filters = { tags: this.filter };

      this.request = {
        type,
        params: {
          ...filters,
          per_page,
          offset,
          order,
          orderby,
          lang: this.$store.state.lang,
        },
      };
    },
    requestOffset() {
      this.offset = this.items.length;
      this.setRequest();
      this.loadItems().then(() => {
        this.$root.$children[0].lazyObserve(this.$el);

        if (this.items.length === this.total) {
          this.theresMore = false;
        }
      });
    },
    setArchive() {
      this.setRequest();

      this.loadItems().then(() => {
        this.$root.$children[0].lazyObserve(this.$el);

        if (this.items.length === this.total) {
          this.theresMore = false;
        }
      });
    },
    setFilter(id) {
      this.filter = id;
      this.items = [];
      this.offset = 0;
      this.total = null;
      this.theresMore = true;
      this.setArchive();
    },
  },
};
</script>

<style lang="scss" scoped>
.archive {
  position: relative;
  @extend %typo--text-small;
  color: var(--gray);

  .accordion-wrapper {
    padding-bottom: var(--spacer-m);
    list-style: none;
    user-select: none;

    ::v-deep .accordion__trigger {
      cursor: pointer;
    }
    ::v-deep .accordion__content .filters {
      padding-top: var(--spacer-m);
      margin-top: var(--spacer-m);
      border-top: 1px solid var(--black);
    }
  }
  .filters {
    user-select: none;
    @include mq(s) {
      padding-bottom: var(--spacer-m);
      .btn:not(:last-child) {
        margin-right: var(--spacer-m);
      }
    }
    .btn {
      cursor: pointer;

      &.btn-active {
        color: var(--black);
        pointer-events: none;
      }
    }
    .featured {
      order: -2;
    }
    .all {
      order: -1;
    }
  }
  .show-more {
    margin-top: var(--spacer-m);
    cursor: pointer;
    user-select: none;
  }
  .item {
    min-height: calc((100vw - 2 * var(--spacer-m)) / 16 * 9);
    @include mq(s) {
      min-height: calc((100vw - 3 * var(--spacer-m)) / 2 / 16 * 9);
    }
  }
}
.infinite {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  height: 50vh;
}
</style>
