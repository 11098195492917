<template>
  <div
    class="ubbbrrru"
    @mouseleave="resetCursor"
    @mousemove="moveCursor"
  >
    <MarqueeText
      :repeat="marqueeRepeat"
      :duration="marqueeDuration"
    >
      <inline-svg :src="require(`@/assets/svg/ubbbrrru.svg`)" />
    </MarqueeText>
    <div
      class="container flex flex-nowrap dir-column justify-center align-center"
    >
      <!-- <div class="audio grid grid-12 row-gap-m justify-center">
          <span>CURRICULUM TAPE</span>
          <Icon
            :key="player.isPlaying"
            :name="player.isPlaying ? 'pause' : 'play'"
            theme="footer"
            :class="{ loading: !player.isReady }"
            :fn="togglePlay"
          />
        </div> -->
      <Video
        :data="options.options.video"
        :fullscreen="false"
        @mouseenter.native="setCursor('pointer_small')"
        @mouseleave.native="setCursor('small')"
      />
      <div class="socials">
        <Icon
          v-for="social in options.options.footer_socials"
          :key="social.name"
          :name="social.name"
          :to="social.url"
          theme="footer"
          @mouseenter.native="setCursor('pointer_small')"
          @mouseleave.native="setCursor('small')"
        />
      </div>
      <div class="stickers">
        <Sticker
          v-for="(sticker, index) in stickers"
          :key="index"
          :data="sticker"
        />
      </div>
    </div>
    <MarqueeText
      :repeat="marqueeRepeat"
      :duration="marqueeDuration"
    >
      <inline-svg :src="require(`@/assets/svg/ubbbrrru.svg`)" />
    </MarqueeText>
    <div
      v-if="!$mq.isTouchDevice"
      :style="{ top: cursor.top, left: cursor.left, display: cursor.display }"
      class="cursor"
    >
      <img
        :src="require(`@/assets/cursor/ubbbrrru_cursor_${cursor.icon}.png`)"
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { spawnStickers } from '@/assets/js/utils';

import MarqueeText from 'vue-marquee-text-component';
import Icon from '@/components/ui/icon';
import Sticker from '@/components/media/sticker';
import Video from '@/components/media/video';

export default {
  components: {
    MarqueeText,
    Icon,
    Video,
    Sticker,
  },
  data() {
    return {
      stickers: null,
      cursor: {
        icon: 'small',
        display: 'none',
        top: 0,
        left: 0,
      },
    };
  },
  computed: {
    ...mapGetters(['options']),
    marqueeRepeat() {
      return 30;
    },
    marqueeDuration() {
      return 2;
    },
  },
  mounted() {
    if (this.options) {
      this.stickers = this.spawnStickers(this.options.options.stickers);
    }
  },
  methods: {
    spawnStickers,
    moveCursor(event) {
      this.cursor.display = 'initial';
      this.cursor.top = `${event.pageY + 77 / 2}px`;
      this.cursor.left = `${event.pageX + 52 / 2}px`;
    },
    setCursor(cursor) {
      this.cursor.icon = cursor;
    },
    resetCursor() {
      this.cursor.display = 'none';
    },
  },
};
</script>

<style lang="scss">
.ubbbrrru {
  background: var(--red);
  color: var(--white);
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: calc(100 * var(--vh));
  overflow: hidden;
  padding-bottom: calc(0.8em + 2 * var(--spacer-m));

  * {
    cursor: none;
  }

  @include mq(s) {
    padding-bottom: 0;
  }

  @include mq($and: $hover) {
    .icon {
      border-radius: 999px;
      overflow: hidden;
    }
    &:hover {
      --blow: 1;
    }
  }
  .marquee-text {
    &-text {
      width: 360px;
      padding: 10px 18px;
      will-change: transform;
    }
  }

  .container {
    position: relative;

    // .audio,
    .socials {
      padding-top: 25px;
      @include mq(s) {
        padding-top: 30px;
      }
    }
    // .audio span {
    //   @extend %typo--bold;
    //   font-size: 40px;
    //   line-height: 40px;
    //   text-align: center;
    //   padding: 0 var(--spacer-m);
    // }
    .video {
      padding: 0 var(--spacer-m);
      @include mq(s) {
        max-width: 50%;
      }
    }
    .socials {
      display: grid;
      // grid-auto-flow: row;
      // row-gap: var(--spacer-l);
      // @include mq(s) {
      grid-auto-flow: column;
      column-gap: 20px;
      // }
    }
    .stickers {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      // width: 100%;
      // height: 100%;
      pointer-events: none;
    }
  }
  .cursor {
    display: none;
    @media (hover: hover) {
      display: initial;
      color: var(--black);
      // mix-blend-mode: difference;
      pointer-events: none;
      position: absolute;
      text-transform: uppercase;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
