<template>
  <div>
    <div>
      <AppHeader v-if="$route.name !== '404'" />
      <div
        v-if="mqBreakpoint !== '0'"
        class="contacts-dsk"
      >
        <div class="grid grid-6 spacer--padding--x--m">
          <div class="empty-col" />
          <div class="grid grid-6">
            <div class="info grid grid-12">
              <div>
                <a
                  :href="'mailto:' + options.options.email"
                  v-html="options.options.email"
                />
                <div v-html="options.options.phone" />
              </div>
              <div class="socials">
                <Icon
                  v-for="social in options.options.header_socials"
                  :key="social.name"
                  :name="social.name"
                  :to="social.url"
                  theme="contacts"
                />
              </div>
            </div>
            <div class="empty-col" />
          </div>
        </div>
      </div>
      <Gutenberg
        :data="$store.state.wp.pages.default.homepage.gds_blocks"
        class="spacer--padding--x--m"
      />
    </div>
    <Ubbbrrru />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import lazyload from '@/mixins/lazyload';

import Gutenberg from '@/components/blocks';
import Icon from '@/components/ui/icon';
import AppHeader from '@/components/ui/header';
import Ubbbrrru from '@/components/ubbbrrru';

export default {
  name: 'Homepage',
  components: {
    Gutenberg,
    Icon,
    AppHeader,
    Ubbbrrru,
  },
  mixins: [lazyload],
  computed: {
    ...mapGetters(['options']),
    mqBreakpoint() {
      return this.$mq.breakpoint;
    },
  },
};
</script>

<style lang="scss" scoped>
.contacts-dsk {
  position: absolute;
  top: var(--spacer-s);
  width: 100%;

  @extend %typo--text-small;
  .info > * {
    width: fit-content;
    pointer-events: auto;
  }
  .socials {
    display: flex;
    align-items: center;
  }
}
</style>
