<template>
  <div class="text spacer--padding--t--m spacer--padding--b--xl">
    <Grid :col="{ default: 12, s: 6 }">
      <div
        class="title spacer--padding--b--m"
        v-html="data.title"
      />
      <div
        class="content"
        v-html="data.content"
      />
    </Grid>
  </div>
</template>

<script>
export default {
  name: 'Txt',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  .title {
    @extend %typo--subtitle;
    @include mq($until: s) {
      border-bottom: 1px solid var(--black);
    }
  }
  .content {
    @extend %typo--text-small;
  }
}
</style>
