import Archive from '@/components/blocks/archive';
import Figure from '@/components/media/figure';
import Gallery from '@/components/media/gallery';
import Heading from '@/components/typo/heading';
import Link from '@/components/typo/link';
import Posts from '@/components/blocks/posts';
import Richtext from '@/components/typo/richtext';
import VideoPlayer from '@/components/media/video';
import Section from '@/components/blocks/section';
import Txt from '@/components/blocks/txt';
import Project from '@/components/blocks/project';

const components = new Map([
  ['archive', Archive],
  ['gallery', Gallery],
  ['heading', Heading],
  ['image', Figure],
  ['link', Link],
  ['posts', Posts],
  ['paragraph', Richtext],
  ['video', VideoPlayer],
  ['section', Section],
  ['text', Txt],
  ['project', Project],
]);

export default components;
