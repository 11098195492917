<template>
  <div
    id="app"
    :class="{ single: $route.name.toLowerCase() === 'single' }"
  >
    <Homepage
      v-if="$route.name !== '404'"
      :class="{ bg: isProject }"
    />
    <transition
      :css="false"
      appear
      mode="out-in"
      @enter="enter"
      @leave="leave"
    >
      <router-view
        v-if="isProject || $route.name === '404'"
        :key="$route.path"
      />
    </transition>
    <AppFooter v-if="$route.name !== '404'" />

    <!-- <Modal /> -->
    <!-- <Snackbar /> -->
    <!-- <Loader /> -->
    <!-- <Cookie v-if="options && options.cookies" /> -->
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';

import Homepage from '@/components/ui/homepage';
import AppFooter from '@/components/ui/footer';

// import Modal from '@/components/ui/modal';
// import Snackbar from '@/components/ui/snackbar';
// import Loader from '@/components/ui/loader';
// import Cookie from '@/components/ui/cookie';

export default {
  name: 'App',
  components: {
    AppFooter,
    Homepage,
    // Modal,
    // Cookie,
    // Loader,
    // Snackbar,
  },
  mixins: [lazyload],
  computed: {
    ...mapGetters(['options']),
    isProject() {
      return this.$route.name.toLowerCase() === 'single';
    },
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
        this.$mq.vh();
      }, 400),
    );

    document.fonts.ready.then(() => {
      this.$store.commit('SET_FONTS', true);
    });
  },
  updated() {
    if (this.$route.name === 'Index') {
      this.$nextTick(() => {
        document.documentElement.scrollTop = document.documentElement.style.getPropertyValue('--bg-scroll-top')
          || 0;
      });
    }
  },
  methods: {
    enter(el, done) {
      this.$store.commit('SET_TRANSITION', false);
      done();
    },
    leave(el, done) {
      this.$store.commit('SET_TRANSITION', true);
      done();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.app-loaded {
  #loader {
    display: none;
  }
}

#app {
  min-height: calc(100 * var(--vh));

  &.single {
    background: var(--black-trans);
    .gutenberg {
      background: none;
    }
  }
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100 * var(--vh));
    // overflow: hidden;
    z-index: -100;
    transform: translateY(calc(var(--bg-scroll-top, 44) * 1px));
  }
}
</style>
