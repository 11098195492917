var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sticker",class:[
    {'sticker--desktop' : _vm.data.desktop},
    {'sticker--mobile' : _vm.data.mobile},
  ],style:({
    '--x': _vm.data.pos.x,
    '--y': _vm.data.pos.y,
    '--rot': _vm.data.rot + 'deg',
    '--dir': _vm.data.pos.x > 0 ? 1 : -1,
    '--time': Math.random() * 0.5 + 0.2 + 's',
  }),on:{"mousedown":_vm.popSticker}},[_c('img',{staticClass:"sticker-image",attrs:{"src":_vm.data.sticker.url}})])
}
var staticRenderFns = []

export { render, staticRenderFns }