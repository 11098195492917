<template>
  <main>
    <div class="not-found flex flex-nowrap dir-column justify-space-between">
      <MarqueeText
        :repeat="marqueeRepeat"
        :duration="marqueeDuration"
      >
        <inline-svg :src="require(`@/assets/svg/404.svg`)" />
      </MarqueeText>
      <inline-svg
        class="pad"
        :src="require(`@/assets/svg/404pad.svg`)"
        @click="$router.push('/')"
      />
      <MarqueeText
        :repeat="marqueeRepeat"
        :duration="marqueeDuration"
      >
        <inline-svg :src="require(`@/assets/svg/404.svg`)" />
      </MarqueeText>
    </div>
  </main>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component';

export default {
  name: 'NotFound',
  components: {
    MarqueeText,
  },
  computed: {
    marqueeRepeat() {
      return 30;
    },
    marqueeDuration() {
      return 2;
    },
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  min-height: calc(100 * var(--vh));
  background: var(--red);
  color: var(--white);

  .marquee-text-text {
    width: 70px;
    padding-right: 4px;
    will-change: transform;
  }
  .pad {
    width: 50%;
    max-width: 630px;
    margin: 120px auto;
    cursor: pointer;
  }
}
</style>
