<template>
  <footer
    v-show="showFooter"
    class="page-footer typo--text-small"
  >
    <div
      v-if="mqBreakpoint === '0'"
      class="contacts-mob"
      :class="{ open: isContactsOpen }"
    >
      <div class="grid grid-12 spacer--padding--x--m">
        <div
          class="c-title flex justify-space-between align-center spacer--padding--y--m"
          @click="toggleContacts"
        >
          <span>Contacts</span>
          <Icon
            v-show="isContactsOpen"
            name="close"
            theme="close-contacts"
          />
        </div>
        <div class="info grid grid-12">
          <a
            :href="'mailto:' + options.options.email"
            v-html="options.options.email"
          />
          <div v-html="options.options.phone" />
          <div class="socials flex align-end spacer--padding--b--m">
            <Icon
              v-for="social in options.options.header_socials"
              :key="social.name"
              :name="social.name"
              :to="social.url"
              theme="contacts"
            />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/icon';

export default {
  name: 'Footer',
  components: {
    Icon,
  },
  data() {
    return {
      isContactsOpen: false,
      scrollTop: 0,
      scrollDelta: 0,
    };
  },
  computed: {
    ...mapGetters(['currentPost', 'options']),
    showFooter() {
      if (this.$route.meta.hideFooter) {
        return false;
      }
      return true;
      // if (!this.$route.meta.customView && !this.currentPost) {
      //   return false;
      // }
      // return true;
    },
    footer() {
      const { footer } = this.options;
      return footer;
    },
    mqBreakpoint() {
      return this.$mq.breakpoint;
    },
  },
  watch: {
    mqBreakpoint() {
      this.isContactsOpen = false;
      this.scrollDelta = 0;
    },
    scrollTop(newVal, oldVal) {
      this.scrollDelta = newVal - oldVal;
      if (this.scrollDelta < 0) this.isContactsOpen = false;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    toggleContacts() {
      this.isContactsOpen = !this.isContactsOpen;
    },
    onScroll() {
      this.scrollTop = document.documentElement.scrollTop;
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  pointer-events: none;

  .single & {
    z-index: -10;
  }

  .contacts-mob {
    --h-contacts: calc(1em + 2 * var(--spacer-m));
    height: calc(50 * var(--vh));
    background: var(--white);
    transform: translateY(calc(100% - var(--h-contacts)));
    transition: transform 0.2s ease-out;
    z-index: 10;
    pointer-events: auto;

    .c-title {
      border-bottom: 1px solid var(--black);
      // padding-top: ;
      cursor: pointer;
      user-select: none;
    }
    &.open {
      transform: translateY(0);
    }
    & > *:first-child {
      height: 100%;
      grid-template-rows: auto 1fr;
    }
    .info {
      grid-template-rows: auto auto 1fr;
    }
  }
}
</style>
