<template>
  <header v-show="!$route.meta.hideHeader">
    <div
      class="header spacer--padding--a--m spacer--padding--y-s--s"
    >
      <div class="gd-wrapper">
        <div
          ref="title"
          class="gd extra big"
          @click="scrollToTop"
          v-html="breakString($store.state.wp.site.name)"
        />
      </div>
      <div class="gd-wrapper">
        <div
          ref="motto"
          class="gd big motto"
          v-html="breakString($store.state.wp.site.description)"
        />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      // scrollTop: 0,
      // scrollListener: false,
    };
  },
  watch: {
    // scrollTop(newVal) {
    //   if (newVal > 100) {
    //     this.$refs.title.classList.remove('big');
    //     this.$refs.motto.classList.remove('big');
    //   } else {
    //     this.$refs.title.classList.add('big');
    //     this.$refs.motto.classList.add('big');
    //   }
    // },
  },
  mounted() {
    // window.addEventListener('scroll', this.onScroll);
    // this.scrollListener = true;
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.onScroll);
    // this.scrollListener = false;
  },
  updated() {
    // if (!this.scrollListener && this.$route.name === 'Index') {
    //   window.addEventListener('scroll', this.onScroll);
    //   this.scrollListener = true;
    // } else if (this.scrollListener && this.$route.name !== 'Index') {
    //   window.removeEventListener('scroll', this.onScroll);
    //   this.scrollListener = false;
    // }
  },
  methods: {
    // onScroll() {
    //   this.scrollTop = document.documentElement.scrollTop;
    // },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    breakString(string) {
      return string.split(' ').join('<br>');
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  position: sticky;
  top: 0;
  color: var(--white);
  mix-blend-mode: difference;
  z-index: 10;
  pointer-events: none;
  padding-bottom: 80px;

  @extend %typo--title;
  @include mq($until: s) {
    padding-bottom: var(--spacer-l);
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  ::selection {
    background-color: var(--white);
    color: var(--black);
  }

  .single & {
    position: fixed;
    width: 100%;
    z-index: -10;
  }

  a {
    pointer-events: auto;
  }

  .gd-wrapper {
    // overflow: hidden;
    // height: 38px;

    .gd {
      pointer-events: auto;
      cursor: pointer;
      transition: transform 0.3s ease;
      transform-origin: top left;

      &.motto {
        text-align: right
      }

      &.big {
        text-transform: uppercase;
        line-height: 0.9;
        font-size: 25px;

        @include mq(s) {
          font-size: 40px
          // transform: scale(1.3);
        }
      }
      &.extra {
        @extend %typo--extra;

        font-size: 38px;

        @include mq(s) {
          font-size: 61px;
        }
      }
    }
  }
}
</style>
