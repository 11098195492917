<template>
  <component
    :is="tag"
    :class="[
      'icon',
      `theme--${theme}`,
      `icon--${name}`,
    ]"
    :to="tag === 'router-link' ? parsedTo : undefined"
    :href="tag === 'a' ? parsedTo : undefined"
    :target="tag === 'a' ? '_blank' : undefined"
    @click.stop="fn"
  >
    <inline-svg :src="require(`@/assets/svg/icons/${name}.svg`)" />
  </component>
</template>

<script>

export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
    fn: {
      type: Function,
      default: () => {},
    },
    to: {
      type: String,
      default: () => {},
    },
  },
  computed: {
    tag() {
      return this.to ? (typeof this.to === 'string' && this.to.startsWith('http') ? 'a' : 'router-link') : 'button';
    },
    parsedTo() {
      // return to ? (typeof to === 'string' ? this.$relativeUrl(to) : to) : undefined;
      return this.to ? (typeof this.to === 'string' && this.to.startsWith('http') ? this.to : this.$relativeUrl(this.to)) : undefined;
    },
  },
};
</script>

<style lang="scss">
.icon {
  // position: relative;
  cursor: pointer;

  // svg {
    // position: absolute;
    // left: 50%;
    // top: 50%;
    // transform: translate3d(-50%, -50%, 0);
  // }

  &--close {
    svg {
      overflow: visible;
    }
  }
}
.theme {
  &--contacts {
    width: 20px;
    height: 20px;
    &:not(:last-child) {
      margin-right: 62px;
      @include mq(s) {
        margin-right: 10px;
      }
    }
  }
  &--footer {
    width: 60px;
    height: 60px;

    &.loading {
      cursor: progress !important;
    }
  }
  &--close-contacts {
    width: 13px;
    height: 13px;
    padding: 1px;
    pointer-events: none;
  }
  &--close-project {
    position: fixed;
    top: var(--spacer-m);
    right: var(--spacer-m);
    width: 13px;
    height: 13px;
    padding: 6px;
    box-sizing: content-box;
    color: var(--white);
    z-index: 10;
    @include mq(s) {
      width: 23px;
      height: 23px;
      padding: 2px;
    }
  }
}
</style>
