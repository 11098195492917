<template>
  <Wrapper
    class="project"
    :size="{ s: 'm' }"
  >
    <Grid :col="{ default: 12 }">
      <Video
        v-if="data.video"
        :data="data.video"
      />
      <Grid
        :col="{ default: 12 }"
        row-gap="0"
      >
        <div
          v-for="row in data.content"
          :key="row.title"
          class="row grid spacer--padding--t--s spacer--padding--b--m spacer--padding--y-s--s"
        >
          <div
            class="title"
            v-html="row.title"
          />
          <div
            class="text"
            v-html="row.text"
          />
        </div>
      </Grid>
    </Grid>
  </Wrapper>
</template>

<script>
import Video from '@/components/media/video';

export default {
  name: 'Project',
  components: {
    Video,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    options() {
      return this.data.options;
    },
  },
};
</script>

<style lang="scss" scoped>
.project {
  padding-top: 42px;
  color: var(--white);

  ::selection {
    background-color: var(--white);
    color: var(--black);
  }
  .row {
    grid-template-columns: 3fr 7fr;

    .title {
      @extend %typo--subtitle;
    }
    .text {
      @extend %typo--project-big;
      &.t-small {
        @extend %typo--project-small;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid var(--white);
    }
  }
  @include mq(s) {
    .video {
      order: 2;
    }
  }
}
</style>
