<template>
  <ul class="accordion-wrapper">
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'AccordionWrapper',
  provide() {
    return { Accordion: this.Accordion };
  },
  props: {
    activeCounter: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      Accordion: {
        count: 0,
        active: this.activeCounter,
      },
    };
  },
  computed: {

  },
};
</script>

<style>
  .accordion-enter-active,
  .accordion-leave-active {
    overflow: hidden;
    transition: 0.3s cubic-bezier(0.65, 0, 0.35, 1);
    transition-property: height, opacity;
    will-change: height, opacity;
  }

  .accordion-enter,
  .accordion-leave-to {
    height: 0 !important;
  }
</style>
