<template>
  <router-link
    :to="$relativeUrl(data.link)"
    class="thumb"
  >
    <Figure
      v-if="data.gds_featured_image"
      :data="{
        value: {
          src: data.gds_featured_image,
        },
        settings: {},
      }"
      theme="default"
    />
    <Heading
      class="title"
      :class="$mq.breakpoint === '0' ? 'title-mob' : 'title-dsk'"
      :value="data.title.rendered"
    />
  </router-link>
</template>

<script>
import Figure from '@/components/media/figure';
import Heading from '@/components/typo/heading';

export default {
  name: 'Thumb',
  components: {
    Figure,
    Heading,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb {
  display: block;
  position: relative;

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--white);
    mix-blend-mode: difference;
    text-align: center;
  }
  .title-dsk {
    @extend %typo--video-title;
  }
  .title-mob {
    @extend %typo--video-title-mobile;
  }
  @include mq($and: $hover) {
    .title {
      opacity: 0;
    }
    &:hover .title {
      opacity: 1;
    }
  }
}
</style>
