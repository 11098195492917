<template>
  <div
    class="sticker"
    :class="[
      {'sticker--desktop' : data.desktop},
      {'sticker--mobile' : data.mobile},
    ]"
    :style="{
      '--x': data.pos.x,
      '--y': data.pos.y,
      '--rot': data.rot + 'deg',
      '--dir': data.pos.x > 0 ? 1 : -1,
      '--time': Math.random() * 0.5 + 0.2 + 's',
    }"
    @mousedown="popSticker"
  >
    <img
      class="sticker-image"
      :src="data.sticker.url"
    >
  </div>
</template>

<script>
export default {
  name: 'Sticker',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    popSticker() {
      if (this.$mq.isTouchDevice) this.$el.classList.add('hidden');
    },
  },
};
</script>

<style lang="scss">
.sticker {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--sticker-size);
  height: var(--sticker-size);
  transform: translate(
      calc(
        (var(--blow, 0) * var(--dir, 1) + var(--x, -100) + 0.5) * 100vw - 50%
      ),
      calc(var(--y, -100) * 25vh)
    )
    rotate(var(--rot, 0deg));
  @include mq(s) {
    transform: translate(
      calc(
        (var(--blow, 0) * var(--dir, 1) + var(--x, -100) + 0.5) * 40vw +
          30vw - 50%
      ),
      calc(var(--y, -100) * 45vh)
    )
    rotate(var(--rot, 0deg));
  }
  transition: transform var(--time) cubic-bezier(0, 0, 0.2, 1);
  cursor: pointer;
  pointer-events: auto;
  user-select: none;
  display: none;
  &--mobile {
    display: block;
  }

  @include mq(s) {
    &--mobile {
      display: none;
      pointer-events: none;
    }
    &--desktop {
      display: block;
    }

  }

  --sticker-size: 210px;
  @include mq(s) {
    --sticker-size: 320px;
  }

  &.hidden {
    display: none;
  }

  .sticker-image {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
