<template>
  <main>
    <Gutenberg
      :data="post.gds_blocks"
      class="spacer--padding--x--m"
    />
  </main>
</template>

<script>
import data from '@/mixins/data';

import Gutenberg from '@/components/blocks';

export default {
  name: 'Page',
  components: {
    Gutenberg,
  },
  mixins: [data],
};
</script>
