<template>
  <main>
    <Wrapper
      class="project spacer--padding--b--xl"
      :size="{ s: 'm' }"
    >
      <Gutenberg
        :data="post.gds_blocks"
        class="spacer--padding--x--m"
      />
    </Wrapper>
    <Icon
      name="close"
      to="/"
      theme="close-project"
    />
  </main>
</template>

<script>
import Gutenberg from '@/components/blocks';
import Icon from '@/components/ui/icon';

import data from '@/mixins/data';

export default {
  name: 'Single',
  components: {
    Gutenberg,
    Icon,
  },
  mixins: [data],
  mounted() {
    document.addEventListener('keydown', this.closeProject);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.closeProject);
  },
  methods: {
    closeProject(e) {
      const key = e.key || e.keyCode;
      if (key === 'Escape' || key === 'Esc' || key === 27) {
        this.$router.push('/');
      }
    },
  },
};
</script>
