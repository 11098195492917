<template>
  <div
    ref="gallery"
    class="gallery"
  >
    <div
      v-for="(item, index) in data.value.gallery"
      :key="index"
      class="item"
    >
      <Figure
        :data="{
          value: {
            src: item,
          },
          settings: {},
        }"
        :lazyload="false"
        disable-ratio
      />
    </div>
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

import Figure from '@/components/media/figure';

require('flickity-imagesloaded');

export default {
  name: 'Gallery',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      gallery: null,
      settings: {
        cellAlign: 'left',
        cellSelector: '.item',
        imagesLoaded: true,
        contain: 'true',
        prevNextButtons: false,
        pageDots: false,
      },
    };
  },
  mounted() {
    this.gallery = new Flickity(
      this.$refs.gallery,
      Object.assign(this.settings, this.extraSettings),
    );
  },
  beforeUnmount() {
    this.gallery.destroy();
  },
};
</script>

<style lang="scss">
  .gallery {
    overflow: hidden;

    .flickity-viewport {
      overflow: visible;
    }
    .item {
      width: 100%;
    }
  }
</style>
