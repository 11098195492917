<template>
  <div :class="['video', computedTheme ? `video--${computedTheme}` : false]">
    <!-- <div
      v-if="source === 'url'"
      ref="player"
      class="plyr"
      v-html="data.value.url"
    /> -->

    <div
      ref="player"
      class="plyr"
      :data-plyr-provider="parseVideoURL(data.value.url).provider"
      :data-plyr-embed-id="parseVideoURL(data.value.url).id"
    />

    <video
      v-if="source === 'file'"
      ref="player"
      class="plyr"
      :data-poster="
        data.settings.poster && data.value.poster
          ? data.value.poster.sizes.l
          : null
      "
      playsinline
    >
      <source
        :src="data.value.src.url"
        type="video/mp4"
      >
    </video>

    <Richtext
      v-if="data.value.caption"
      :data="{ innerHTML: data.value.caption }"
    />
  </div>
</template>

<script>
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import Richtext from '@/components/typo/richtext';
import { mapGetters } from 'vuex';
import { parseVideoURL } from '../../assets/js/utils';

export default {
  name: 'VideoPlayer',
  components: {
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    theme: {
      type: [String, Boolean],
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      plyr: null,
      src: null,
      hls: null,
      currentVideo: 'default',
      source: 'url',
    };
  },
  computed: {
    ...mapGetters(['plyrInstances']),
    video() {
      let value = false;
      if (
        this.currentVideo === 'mobile'
        && this.data.settings.mobile_toggle
        && this.data.value.src_mobile
      ) {
        if (this.data.settings.mobile.source === 'url') {
          value = this.data.value.url_mobile;
        } else value = this.data.value.src_mobile.url;
      } else if (this.data.settings.source === 'url') {
        value = this.data.value.url;
      } else {
        value = this.data.value.src.url;
      }
      return value;
    },
    computedTheme() {
      let value = false;
      if (this.theme) {
        value = this.theme;
      } else if (this.data.settings.style) {
        value = this.data.settings.style;
      }
      return value;
    },
  },
  watch: {
    src(value) {
      if (value) {
        this.$nextTick(() => {
          const { player } = this.$refs;
          if (this.plyr) {
            player.load();
            // this.plyr.play();
          } else if (this.source === 'url') {
            this.plyr = new Plyr(player, {
              controls: [
                'play',
                'play-large',
                'progress',
                'current-time',
                'duration',
                'mute',
                'fullscreen',
              ],
              playsinline: true,
              youtube: {
                playsinline: true,
              },
              storage: {
                enabled: false,
              },
            });
          } else if (this.source === 'file') {
            this.plyr = new Plyr(player, {
              autoplay: true,
              controls: [],
              fullscreen: false,
              storage: {
                enabled: false,
              },
              loop: {
                active: true,
              },
              muted: true,
            });
          }
          player.addEventListener('loadedmetadata', () => {
            player.play();
          });
          if (this.plyr) {
            this.$store.commit('SET_PLYR_INSTANCE', this.plyr);
          }
          this.plyr.on('play', () => {
            console.log(this.plyr.id);
            this.plyrInstances.forEach((element) => {
              if (element.id !== this.plyr.id) {
                element.pause();
              }
            });
          });
        });
      }
    },
  },
  mounted() {
    this.currentSrc();
    this.$bus.$on('windowResized', this.currentSrc);
  },
  beforeDestroy() {
    if (this.plyr) {
      this.$store.commit('REMOVE_PLYR_INSTANCE', this.plyr.id);
    }
    this.$bus.$off('windowResized', this.currentSrc);
  },
  methods: {
    currentSrc() {
      let src = this.video;
      this.$nextTick(() => {
        if (
          this.$mq.isMobile
          && this.data.settings.mobile_toggle
          && this.data.value.src_mobile
        ) {
          this.currentVideo = 'mobile';

          if (this.data.settings.mobile.source === 'url') {
            this.source = 'url';
          } else this.source = 'file';
        } else {
          this.currentVideo = 'default';

          if (this.data.settings.source === 'url') {
            this.source = 'url';
          } else this.source = 'file';
        }

        src = this.video;
        this.src = src;
      });
    },
    parseVideoURL(url) {
      return parseVideoURL(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.video {
  position: relative;
  width: 100%;
  padding: var(--spacer-m) 0;
}
</style>

<style lang="scss">
.plyr {
  --plyr-color-main: var(--white);
  --plyr-video-control-background-hover: var(--black-trans);
  // --plyr-control-icon-size: 21px;
  --plyr-range-track-height: 3px;
  background: transparent;
  &__video-wrapper {
    background: transparent;
  }

  cursor: pointer;

  .plyr__progress__buffer {
    border-radius: 0px;
  }

  .plyr__progress input[type='range'] {
    border-radius: 0px;
  }

  .plyr__tooltip {
    display: none;
  }

  &__control {
    &--overlaid {
      opacity: 0 !important;
      width: 100% !important;
      height: 100% !important;
    }
  }

  .plyr__volume {
    width: auto;
    max-width: auto;
    min-width: auto;
  }
  .plyr--youtube .plyr__poster {
    pointer-events: none;
  }
}
</style>
