var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"archive spacer--padding--b--xl"},[(_vm.data.value.title)?_c('Heading',{attrs:{"value":_vm.data.value.title}}):_vm._e(),(_vm.$mq.breakpoint !== '0')?_c('div',{staticClass:"filters flex"},[_c('div',{class:['btn', { 'btn-active': _vm.filter === null }, 'all'],on:{"click":function($event){return _vm.setFilter(null)}}},[_vm._v(" All ")]),_vm._l((_vm.activeTags),function(tag){return _c('div',{key:tag.id,class:[
        'btn',
        {
          'btn-active': _vm.filter === tag.id,
          featured: tag.slug === 'featured',
        },
      ],domProps:{"innerHTML":_vm._s(tag.name)},on:{"click":function($event){return _vm.setFilter(tag.id)}}})})],2):_c('AccordionWrapper',[_c('Accordion',{scopedSlots:_vm._u([{key:"accordion-trigger",fn:function(){return [_vm._v(" Show Filters ")]},proxy:true},{key:"accordion-trigger-active",fn:function(){return [_vm._v(" Close Filters ")]},proxy:true},{key:"accordion-content",fn:function(){return [_c('div',{staticClass:"filters grid grid-12"},[_c('div',{class:[
              'btn',
              { 'btn-active': _vm.filter === null },
              'all',
            ],on:{"click":function($event){return _vm.setFilter(null)}}},[_vm._v(" All ")]),_vm._l((_vm.activeTags),function(tag){return _c('div',{key:tag.id,class:[
              'btn',
              {
                'btn-active': _vm.filter === tag.id,
                featured: tag.slug === 'featured',
              },
            ],domProps:{"innerHTML":_vm._s(tag.name)},on:{"click":function($event){return _vm.setFilter(tag.id)}}})})],2)]},proxy:true}])})],1),(_vm.items.length > 0)?_c('Grid',{attrs:{"col":{ default: 12, s: 6 }}},_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"item"},[_c(_vm.thumbs.get('default'),{tag:"component",attrs:{"data":item}})],1)}),0):_c('div',{staticStyle:{"height":"100vh"}}),(_vm.theresMore)?_c('div',{staticClass:"show-more",domProps:{"innerHTML":_vm._s('Show More')},on:{"click":_vm.requestOffset}}):_vm._e(),(_vm.data.value.link)?_c('Link',{attrs:{"data":_vm.data.value.link}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }