function deepEqual(x, y) {
  const ok = Object.keys; const tx = typeof x; const
    ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? (
    ok(x).length === ok(y).length
      && ok(x).every((key) => deepEqual(x[key], y[key]))
  ) : (x === y);
}

export default {
  SET_LANG(state, value) {
    state.lang = value;
  },
  SET_CURRENT_POST(state, value) {
    state.currentPost = value;
  },
  SET_TRANSITION(state, value) {
    state.transition = value;
  },
  SET_FONTS(state, value) {
    state.fontsLoaded = value;
  },
  SET_SNACKBAR(state, value) {
    state.snackbar = value;
  },
  SET_PLYR_INSTANCE(state, item) {
    let exist = false;
    state.plyrInstances.forEach((element) => {
      if (deepEqual(element, item)) {
        exist = true;
      }
    });
    if (!exist) {
      state.plyrInstances.push(item);
    }
  },
  REMOVE_PLYR_INSTANCE(state, id) {
    const index = state.plyrInstances.findIndex((element) => element.id === id);
    if (index) {
      delete state.plyrInstances[index];
    }
  },
};
